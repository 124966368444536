<!--  -->
<template>
  <div class="hzdw">
    <div class="ywlybanner">
      <img src="../assets/hzdw.jpg" alt="" />
    </div>

    <div class="hzdwcontent">
      <div class="mm" v-for="(item,index) in hzdwcontent" :key='index' @click="link(item.linkUrl)">
        <div class="ss"><img :src="'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId='+item.logo" alt="" /></div>
        <div class="ss1">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      hzdwcontent:[]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    link(url){
       window.location.href = url;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$api
      .cooperationUnitapiList({})
      .then((res) => {
        this.hzdwcontent = res.data
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.hzdw {
  min-height: 72vh;
}
.ywlybanner {
  // height: 280px;
  img {
    width: 100%;
    // height: 100%;
   
  }
}

.ss {
  width: 246px;
  height: 184px;
  margin: auto;
  img {
    width: 100%;
    height: 144px;
     margin: 25px 0;
    border: 1px solid #EFEFEF;
  }
}
.ss1 {
  font-size: 16px;
  color: #333333;
  letter-spacing: 0.91px;
  text-align: center;
  text-align: center;
  margin-top: 14px;
}
.mm {
  width: 300px;
  margin: 43px 0;
  cursor: pointer;
}
.mm:hover{
      transform: translateY(-5px);
    transition-duration: 0.5s;
    box-shadow: 0px 7px 7px -7px #5e5e5e;
}
.hzdwcontent {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
</style>